<template>
  <revenue
    :loading="loading"
    :chart-options="getChartOptions"
    :current-user-context="currentUserContext"
    :can-refetch="canRefetch"
    @refetch="refetch"
  />
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import Revenue from './Revenue'

export default {
  name: 'RevenuePerHour',
  components: {
    Revenue
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      revenuePerHour: [],
      loading: false,
      canRefetch: false
    }
  },
  computed: {
    ...mapGetters({
      timezone: 'Config/getTimeZone'
    }),
    selectedDates() {
      return this.currentUserContext.selectedDates
    },
    resourceQuery() {
      // global branch filter overrides local branch filter
      let branchNumber
      if (Number.isFinite(this.currentUserContext.currentBranchNumber)) {
        branchNumber = this.currentUserContext.currentBranchNumber
      }

      const query = {
        location: this.currentUserContext.currentLocation || undefined,
        branch_number: branchNumber,
        timezone: this.timezone || undefined,
        start: this.selectedDates.start,
        end: this.selectedDates.end
      }

      return query
    },
    getChartOptions() {
      return {
        title: this.$t('pages.home.widgets.revenue_per_hour.title'),
        xAxis: {
          name: this.$t('pages.reports.statistics.overview.hour')
        },
        series: [
          {
            name: this.$t('pages.reports.statistics.overview.turnover'),
            data: this.revenuePerHour,
            type: 'bar'
          }
        ]
      }
    }
  },
  watch: {
    selectedDates(value) {
      this.updateRevenuePerHour()
    }
  },
  mounted() {
    this.updateRevenuePerHour()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async updateRevenuePerHour() {
      if (!this.selectedDates.start || !this.selectedDates.end) {
        this.revenuePerHour = undefined
        return
      }

      this.loading = true
      try {
        const resp = await th
          .analytics({ timeout: 90000 })
          .getRevenuesForHourOfDay(this.resourceQuery)
        this.revenuePerHour = resp.data[0].values
          .reduceRight(this.trimEdgeHoursReducer, []) //removing late evening non-working hours
          .reduceRight(this.trimEdgeHoursReducer, []) //removing early morning non-working hours
          .map((point) => [`${point[0]}:00`, point[1]]) //transformaing data
      } catch (e) {
        this.canRefetch = true
        this.revenuePerHour = []
      } finally {
        this.loading = false
      }
    },
    trimEdgeHoursReducer(result, point) {
      //Don't add point to result, if value is null and the result still empty
      if (point[1] === null && !result.length) {
        return result
      }
      result.push(point)
      return result
    },
    refresh() {
      this.$nextTick(() => {
        this.refetch()
      })
    },
    refetch() {
      this.updateRevenuePerHour()
      this.canRefetch = false
    }
  }
}
</script>
